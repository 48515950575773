export interface InvoiceItem {
  id: number
  description: string
  quantity: number
  unitPrice: number
  total: number
}

export interface Invoice {
  id: number
  invoiceNumber: string // Format: INV-YYYY-XXXX
  quoteReference: string // Reference to quote
  customerName: string
  partnerName: string
  issueDate: string
  dueDate: string
  status: 'Draft' | 'Issued' | 'Paid'
  items: InvoiceItem[]
  subtotal: number
  tax: number
  total: number
}

// Mock data for development
export const mockInvoices: Invoice[] = [
  {
    id: 1,
    invoiceNumber: 'INV-2025-0001',
    quoteReference: 'QUOTE-2025-001',
    customerName: 'Acme Corporation',
    partnerName: 'CredsNow Partner',
    issueDate: '2025-02-08',
    dueDate: '2025-03-08',
    status: 'Draft',
    items: [
      {
        id: 1,
        description: 'Event Management Service',
        quantity: 1,
        unitPrice: 1000,
        total: 1000,
      },
      {
        id: 2,
        description: 'Badge Printing Service',
        quantity: 100,
        unitPrice: 10,
        total: 1000,
      },
    ],
    subtotal: 2000,
    tax: 200,
    total: 2200,
  },
  {
    id: 2,
    invoiceNumber: 'INV-2025-0002',
    quoteReference: 'QUOTE-2025-002',
    customerName: 'TechCorp Solutions',
    partnerName: 'CredsNow Partner',
    issueDate: '2025-02-07',
    dueDate: '2025-03-07',
    status: 'Issued',
    items: [
      {
        id: 1,
        description: 'Event Setup and Configuration',
        quantity: 1,
        unitPrice: 1500,
        total: 1500,
      },
    ],
    subtotal: 1500,
    tax: 150,
    total: 1650,
  },
]
