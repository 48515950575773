import { ReactElement, useState } from 'react'
import { Form, Input, DatePicker, Select, InputNumber, Button, Space, Card, message } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { mockInvoices } from '../../models/manager/invoice'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { PartnerLayout } from '../../components/layout/PartnerLayout'

interface FormValues {
  invoiceNumber: string
  quoteReference: string
  customerName: string
  status: string
  issueDate: dayjs.Dayjs
  dueDate: dayjs.Dayjs
  items: Array<{
    description: string
    quantity: number
    unitPrice: number
  }>
}

export default function EditInvoice(): ReactElement {
  const { id } = useParams()
  const navigate = useNavigate()
  const [form] = Form.useForm<FormValues>()

  const invoice = id ? mockInvoices.find((inv) => inv.id === Number(id)) : undefined
  const [loading, setLoading] = useState(false)

  const onFinish = () => {
    setLoading(true)
    try {
      // Save invoice logic will go here
      message.success('Invoice saved successfully')
      navigate('/partners/invoices')
    } finally {
      setLoading(false)
    }
  }

  return (
    <PartnerLayout>
      <div className="max-w-4xl mx-auto">
        <Card title={id ? 'Edit Invoice' : 'Create Invoice'} className="shadow-md">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={
              invoice
                ? {
                    ...invoice,
                    issueDate: dayjs(invoice.issueDate),
                    dueDate: dayjs(invoice.dueDate),
                  }
                : {
                    status: 'Draft',
                    items: [{ description: '', quantity: 1, unitPrice: 0 }],
                  }
            }
          >
            <div className="grid grid-cols-2 gap-4">
              <Form.Item
                name="invoiceNumber"
                label="Invoice Number"
                rules={[{ required: true, message: 'Please enter invoice number' }]}
              >
                <Input placeholder="INV-YYYY-XXXX" />
              </Form.Item>

              <Form.Item
                name="quoteReference"
                label="Quote Reference"
                rules={[{ required: true, message: 'Please enter quote reference' }]}
              >
                <Input placeholder="QUOTE-YYYY-XXX" />
              </Form.Item>

              <Form.Item
                name="customerName"
                label="Customer Name"
                rules={[{ required: true, message: 'Please enter customer name' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="status" label="Status" rules={[{ required: true, message: 'Please select status' }]}>
                <Select>
                  <Select.Option value="Draft">Draft</Select.Option>
                  <Select.Option value="Issued">Issued</Select.Option>
                  <Select.Option value="Paid">Paid</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="issueDate"
                label="Issue Date"
                rules={[{ required: true, message: 'Please select issue date' }]}
              >
                <DatePicker className="w-full" />
              </Form.Item>

              <Form.Item
                name="dueDate"
                label="Due Date"
                rules={[{ required: true, message: 'Please select due date' }]}
              >
                <DatePicker className="w-full" />
              </Form.Item>
            </div>

            <div className="mt-6">
              <h3 className="text-lg font-medium mb-4">Items</h3>
              <Form.List name="items">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} className="flex mb-4" align="baseline">
                        <Form.Item
                          {...restField}
                          name={[name, 'description']}
                          rules={[{ required: true, message: 'Missing description' }]}
                        >
                          <Input placeholder="Item description" className="w-80" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'quantity']}
                          rules={[{ required: true, message: 'Missing quantity' }]}
                        >
                          <InputNumber min={1} placeholder="Qty" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'unitPrice']}
                          rules={[{ required: true, message: 'Missing unit price' }]}
                        >
                          <InputNumber<number>
                            min={0}
                            placeholder="Unit price"
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => {
                              if (!value) return 0
                              const parsed = Number(value.replace(/\$\s?|(,*)/g, ''))
                              return isNaN(parsed) ? 0 : parsed
                            }}
                          />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add Item
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>

            <div className="flex justify-end mt-6 space-x-4">
              <Button onClick={() => navigate('/partners/invoices')}>Cancel</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save Invoice
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </PartnerLayout>
  )
}
