import { ReactElement, useState } from 'react'
import { Card, Typography } from 'antd'
import { InvoiceList } from '../../components/partners/InvoiceList'
import { mockInvoices } from '../../models/manager/invoice'
import { PartnerLayout } from '../../components/layout/PartnerLayout'

const { Title } = Typography

export default function PartnerInvoices(): ReactElement {
  const [loading] = useState(false)

  return (
    <PartnerLayout>
      <div className="p-8 pt-4 bg-gray-50 min-h-screen">
        <div className="mb-6">
          <Title level={2} className="mb-4">
            Partner Invoices
          </Title>
          <Card>
            <InvoiceList invoices={mockInvoices} loading={loading} />
          </Card>
        </div>
      </div>
    </PartnerLayout>
  )
}
