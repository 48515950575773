import { ReactElement } from 'react'
import { Menu } from 'antd'
import { useLocation } from 'react-router-dom'
import { MenuItems } from '../Menu/MenuItems'
import { useProfileContext } from '../../contexts/profileContext'
import { filterMenuItems, transformMenuItem } from '../../utils/extendedMenu'

export function AppHeaderDesktopMenu(): ReactElement {
  const location = useLocation()
  const { pathname } = location
  const { profile } = useProfileContext()
  const transformedMenuItems = filterMenuItems(MenuItems, profile).map(transformMenuItem)

  const selectedKey = transformedMenuItems.find((item) => pathname.startsWith(String(item.key)))?.key

  return (
    <Menu
      selectedKeys={[String(selectedKey || pathname)]}
      defaultSelectedKeys={[String(selectedKey || pathname)]}
      className="justify-end w-full"
      items={transformedMenuItems}
      mode="horizontal"
    />
  )
}
