import { RecordDataDto, RecordDataBatchDto } from '../models/Dtos/RecordDataDto'
import { RecordSetDto } from '../models/Dtos/RecordSetDto'
import { FieldSet } from '../models/manager/fieldset'
import { RecordAuxRequest, DbRecord, RecordRegistrantTypeDto } from '../models/manager/record'
import { RecordSet } from '../models/manager/recordset'
import { apiRequest } from '../utils/axios'
import { kioskApiRequest } from './kioskApp'
import { createUrl } from '../utils/helper'

// record-set functions
export async function getRecordSetByTemplateId(templateId: number, token: string): Promise<RecordSet> {
  return apiRequest('GET', `/recordSetDataByTemplateId/${templateId}`, token)
}

export async function getRecordSetDataByRecordSetId(
  recordSetId: number,
  token: string,
  kioskKey?: string
): Promise<RecordSetDto> {
  return kioskKey && kioskKey.length > 0
    ? (await kioskApiRequest('GET', `/kioskApp/recordSetDataByRecordSetId/${recordSetId}`, { 'X-Kiosk-Key': kioskKey }))
        .data
    : apiRequest('GET', `/recordSetDataByRecordSetId/${recordSetId}`, token)
}

export async function fetchRecordSets(accountId: number, token: string): Promise<RecordSet[]> {
  return apiRequest('GET', `/recordSets/${accountId}`, token)
}

export async function fetchRecord(recordId: number, token: string, kioskKey?: string): Promise<DbRecord> {
  return kioskKey && kioskKey.length > 0
    ? (await kioskApiRequest('GET', `/kioskApp/record/${recordId}`, { 'X-Kiosk-Key': kioskKey })).data
    : apiRequest('GET', `/record/${recordId}`, token)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function fetchRecordWithoutData(recordId: number, token: string, kioskKey?: string): Promise<any> {
  return kioskKey && kioskKey.length > 0
    ? (await kioskApiRequest('GET', `/kioskApp/recordWithoutData/${recordId}`, { 'X-Kiosk-Key': kioskKey })).data
    : apiRequest('GET', `/recordWithoutData/${recordId}`, token)
}

export async function saveRecordSet(recordSetData: RecordSetDto, token: string): Promise<RecordSetDto> {
  return apiRequest('POST', '/recordSet', token, recordSetData)
}

export async function updateRecordSet(recordSetData: RecordSetDto, token: string): Promise<RecordSetDto> {
  return apiRequest('PUT', '/recordSet', token, recordSetData)
}

export async function deleteRecordSet(recordSetId: number, token: string): Promise<void> {
  return apiRequest('DELETE', `/recordSet/${recordSetId}`, token)
}

// record functions
// format of filter: { 'fieldName': 'value', 'fieldName': 'value' }
// format of sort: { 'fieldName': 'asc' or 'fieldName': 'desc' }
export async function getRecordsByRecordSetId(
  recordSetId: number,
  token: string,
  page: number | null = null,
  take: number | null = null,
  search: string | null = null,
  kioskKey: string | null = null,
  filter: string | null = null,
  sort: string | null = null,
  express: boolean | null = null
): Promise<RecordAuxRequest[]> {
  const params = {
    page: page,
    take: take,
    search: search,
    kioskKey: kioskKey,
    filter: filter,
    sort: sort,
    express: express,
  }
  const url = createUrl(`${kioskKey && kioskKey.length > 0 ? '/kioskApp' : ''}/records/${recordSetId}`, params)
  return kioskKey && kioskKey.length > 0
    ? (await kioskApiRequest('GET', url, { 'X-Kiosk-Key': kioskKey })).data
    : apiRequest('GET', url, token)
}

export async function countRecords(recordSetId: number, token: string): Promise<number> {
  return apiRequest('GET', `/recordCount/${recordSetId}`, token)
}

export async function countListsForAccount(accountId: number, token: string): Promise<number> {
  return apiRequest('GET', `/recordSetCountForAccount/${accountId}`, token)
}

export async function checkedInRecordsForEvent(eventId: number, token: string): Promise<DbRecord[]> {
  return apiRequest('GET', `/recordsCheckedInByEventId/${eventId}`, token)
}

export async function registrationIdsForEvent(eventId: number, token: string): Promise<DbRecord[]> {
  return apiRequest('GET', `/eventRegistrationIds/${eventId}`, token)
}

export async function saveRecordData(
  recordData: RecordDataDto,
  token: string,
  kioskKey?: string
): Promise<RecordDataDto> {
  return kioskKey && kioskKey.length > 0
    ? (await kioskApiRequest('POST', '/kioskApp/recordData', { 'X-Kiosk-Key': kioskKey }, recordData)).data
    : apiRequest('POST', '/recordData', token, recordData)
}

export async function saveRecordDataBatch(recordData: RecordDataBatchDto, token: string): Promise<RecordDataDto> {
  return apiRequest('POST', '/recordDataBatch', token, recordData)
}

export async function updateRecordData(
  recordData: RecordDataDto,
  token: string,
  kioskKey?: string
): Promise<RecordDataDto> {
  return kioskKey && kioskKey.length > 0
    ? (await kioskApiRequest('PUT', '/kioskApp/recordData', { 'X-Kiosk-Key': kioskKey }, recordData)).data
    : apiRequest('PUT', '/recordData', token, recordData)
}

export async function updateRecordRegistrantType(
  recordData: RecordRegistrantTypeDto,
  token: string,
  kioskKey?: string
): Promise<void> {
  return kioskKey && kioskKey.length > 0
    ? (await kioskApiRequest('PUT', '/kioskApp/recordRegistrantType', { 'X-Kiosk-Key': kioskKey }, recordData)).data
    : apiRequest('PUT', '/recordRegistrantType', token, recordData)
}

export async function deleteRecord(recordId: number, token: string): Promise<void> {
  return apiRequest('DELETE', `/recordData/${recordId}`, token)
}

export async function deleteRecordAll(recordSetId: number, token: string): Promise<void> {
  return apiRequest('DELETE', `/recordDataAll/${recordSetId}`, token)
}

//field-set functions
export async function fetchFieldSet(recordSetId: number, token: string, kioskKey?: string): Promise<FieldSet[]> {
  return kioskKey && kioskKey.length > 0
    ? (await kioskApiRequest('GET', `/kioskApp/fieldSet/${recordSetId}`, { 'X-Kiosk-Key': kioskKey })).data
    : apiRequest('GET', `/fieldSet/${recordSetId}`, token)
}

export async function saveFieldSet(fieldSetData: FieldSet, token: string): Promise<number> {
  return apiRequest('POST', '/fieldSet', token, fieldSetData)
}

export async function updateFieldSet(fieldSetData: FieldSet, token: string): Promise<void> {
  return apiRequest('PUT', '/fieldSet', token, fieldSetData)
}

export async function updateFieldSetOverrideFunction(
  fieldSetId: number,
  overrideFunction: string,
  token: string
): Promise<void> {
  return apiRequest('PUT', '/fieldSetOverrideFunction', token, { Id: fieldSetId, OverrideFunction: overrideFunction })
}

export async function deleteFieldSet(fieldSetId: number, token: string): Promise<void> {
  return apiRequest('DELETE', `/fieldSet/${fieldSetId}`, token)
}

export async function unVerifyRecord(recordId: number, token: string, kioskKey?: string): Promise<void> {
  return kioskKey && kioskKey.length > 0
    ? (await kioskApiRequest('PUT', `/kioskApp/recordUnVerify`, { 'X-Kiosk-Key': kioskKey }, { RecordId: recordId }))
        .data
    : apiRequest('PUT', `/recordUnVerify`, token, { RecordId: recordId })
}
