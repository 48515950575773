import { ReactElement, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Card, Table, Typography, Space, Row, Col } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { fetchPartnerDiscounts, fetchPartnerQuotes } from '../../services/partners'
import calculatorIcon from '../../assets/images/partner/calculator.svg'
import { TagOutlined } from '@ant-design/icons'
import { errorHandler } from '../../utils/helper'
import { useProfileContext } from '../../contexts/profileContext'
import { PartnerLayout } from '../../components/layout/PartnerLayout'

interface EnterpriseDiscountTableData {
  level: string
  eventsPerYear: string
  discount: string
}

const headerCellStyle = {
  backgroundColor: '#DDF2E3',
  color: '#000000',
  fontSize: '14px',
  fontWeight: '500',
  padding: '12px 24px',
}

const { Title, Text, Paragraph } = Typography

export default function PartnerDashboard(): ReactElement {
  const { getAccessTokenSilently } = useAuth0()
  const { profile } = useProfileContext()

  const [enterpriseDiscountData, setEnterpriseDiscountData] = useState<EnterpriseDiscountTableData[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [quoteCount, setQuoteCount] = useState<number>(0)

  const enterpriseDiscountColumns: ColumnsType<EnterpriseDiscountTableData> = [
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      onHeaderCell: () => ({
        style: headerCellStyle,
      }),
    },
    {
      title: 'Events per year/Client',
      dataIndex: 'eventsPerYear',
      key: 'eventsPerYear',
      onHeaderCell: () => ({
        style: headerCellStyle,
      }),
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
      onHeaderCell: () => ({
        style: headerCellStyle,
      }),
    },
  ]

  const loadQuoteCount = async () => {
    if (!profile?.AccountId) return
    try {
      const token = await getAccessTokenSilently()
      const quotesData = await fetchPartnerQuotes(profile.AccountId, token)
      setQuoteCount(quotesData.length)
    } catch (error) {
      errorHandler(error)
    }
  }

  const loadDiscounts = async () => {
    try {
      setIsLoading(true)
      const token = await getAccessTokenSilently()
      const discounts = await fetchPartnerDiscounts(token)
      const tableData = discounts.map((discount) => ({
        level: discount.DiscountLevelName,
        eventsPerYear: `${discount.EventCountMinimum}${
          discount.EventCountMaximum < 999 ? ` – ${discount.EventCountMaximum}` : '+'
        } Events`,
        discount: `${discount.DiscountPercentage}%`,
      }))
      setEnterpriseDiscountData(tableData)
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadDiscounts()
    loadQuoteCount()

    const intervalId = setInterval(loadQuoteCount, 5 * 60 * 1000)
    return () => clearInterval(intervalId)
  }, [])

  return (
    <PartnerLayout>
      <div className="p-8 pt-4 bg-gray-50 min-h-screen">
        <div className="mb-6">
          <Title level={2} className="mb-4">
            Partner Dashboard
          </Title>
          <Row gutter={16}>
            <Col span={16}>
              <Card
                className="shadow-md bg-white overflow-hidden"
                title={
                  <div className="bg-[#1D8EF1] -mx-6 px-6 py-3">
                    <span className="text-white font-medium pl-4 text-lg tracking-wide">Welcome</span>
                  </div>
                }
                headStyle={{
                  padding: 0,
                  border: 'none',
                  marginBottom: 0,
                  backgroundColor: '#1D8EF1',
                }}
                bodyStyle={{
                  padding: '24px',
                }}
              >
                <Paragraph className="text-gray-700 leading-relaxed mb-6 text-base">
                  Use this as your pricing guide in working on preparing quotes for your clients. Enterprise pricing
                  discounts involve a commitment to the required number of events. CredsNow Partner is welcome to markup
                  this pricing if they want. Any technical project management or custom development can be quoted
                  separately based on requirements.
                </Paragraph>
                <Paragraph className="text-gray-700 text-base font-medium">
                  Use the pricing calculator to obtain your event pricing
                </Paragraph>
              </Card>
            </Col>
            <Col span={8}>
              <Card
                className="shadow-md bg-white overflow-hidden h-full"
                title={
                  <div className="bg-[#1D8EF1] -mx-6 px-6 py-3">
                    <span className="text-[#ffffff] font-medium pl-4 text-lg tracking-wide">Quotes Created</span>
                  </div>
                }
                headStyle={{
                  padding: 0,
                  border: 'none',
                  marginBottom: 0,
                  backgroundColor: '#1D8EF1',
                }}
                bodyStyle={{
                  padding: '24px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text className="text-gray-600 text-lg mb-2">Total Quantity</Text>
                <Title level={1} className="!m-0">
                  {quoteCount}
                </Title>
              </Card>
            </Col>
          </Row>
        </div>

        <div className="mb-8">
          <Space className="mb-4" align="center" size={12}>
            <img src={calculatorIcon} alt="Calculator" className="w-6 h-6" />
            <Title level={4} className="!mb-0 !font-bold">
              Core Pricing Components
            </Title>
          </Space>
          <Card
            className="shadow-md bg-white overflow-hidden"
            title={
              <div className="bg-[#1D8EF1] -mx-6 px-6 py-3">
                <span className="text-white font-medium pl-4 text-lg tracking-wide">Pricing Description</span>
              </div>
            }
            headStyle={{
              padding: 0,
              border: 'none',
              marginBottom: 0,
              backgroundColor: '#1D8EF1',
            }}
            bodyStyle={{
              padding: '24px',
            }}
          >
            <ul className="list-disc pl-4 space-y-4">
              <li>
                <Text strong>Event Mode Activation Fee:</Text> $250.00 per event
              </li>
              <li>
                <div>
                  <Text strong>Software Access Fee (Per Badge):</Text>
                  <ul className="list-disc pl-8 mt-2 space-y-2">
                    <li>
                      Up to 1,000 badges – <Text strong>$1.00 per badge</Text>
                    </li>
                    <li>
                      1,001 – 2,500 badges – <Text strong>$0.75 per badge</Text>
                    </li>
                    <li>
                      2,501 – 10,000 badges – <Text strong>$0.50 per badge</Text>
                    </li>
                    <li>
                      10,001 – 25,000 badges – <Text strong>$0.40 per badge</Text>
                    </li>
                    <li>
                      25,001+ badges – <Text strong>$0.25 per badge</Text>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <Text strong>Print Agent License Fee:</Text> $50.00 per printer per event
              </li>
              <li>
                <Text strong>Revenue Share Fee (if billed via CredsNow):</Text> 25% per billed additional partner
                services. CredsNow handles all account billing and financial management. Partner will be paid out for
                their portion of services directly by Creds.
              </li>
            </ul>
          </Card>
        </div>

        <div className="mb-8">
          <Space className="mb-4" align="center" size={12}>
            <TagOutlined className="text-xl" />
            <Title level={4} className="!mb-0 !font-bold">
              Enterprise Discounts
            </Title>
          </Space>
          <Table
            columns={enterpriseDiscountColumns}
            dataSource={enterpriseDiscountData}
            pagination={false}
            loading={isLoading}
            className="shadow-md"
          />
        </div>

        <div className="mb-8">
          <Space className="mb-4" align="center" size={12}>
            <img src={calculatorIcon} alt="Calculator" className="w-6 h-6" />
            <Title level={4} className="!mb-0 !font-bold">
              Example Pricing
            </Title>
          </Space>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <Card
              title={
                <div className="w-full min-h-[80px] flex items-center">
                  <span className="block leading-tight max-w-[200px]">
                    Small Event
                    <br />
                    (500 Attendees, 2 Print Agent Licenses)
                  </span>
                </div>
              }
              className="shadow-md bg-white overflow-hidden"
              headStyle={{
                padding: '12px 16px',
                border: 'none',
                margin: 0,
                backgroundColor: '#dbeafe',
                color: '#000000',
                fontWeight: 500,
                whiteSpace: 'normal',
                height: 'auto',
              }}
            >
              <ul className="list-disc pl-4 space-y-2">
                <li>Event Activation Fee: $250</li>
                <li>Software Access Fee (500 badges): $500</li>
                <li>Print Agent License Fee (2 Licenses): $100</li>
                <li>
                  <Text strong>Total: $850</Text>
                </li>
              </ul>
            </Card>
            <Card
              title={
                <div className="w-full min-h-[80px] flex items-center">
                  <span className="block leading-tight max-w-[200px]">
                    Medium Event
                    <br />
                    (1,000 Attendees, 4 Print Agent Licenses)
                  </span>
                </div>
              }
              className="shadow-md bg-white overflow-hidden"
              headStyle={{
                padding: '12px 16px',
                border: 'none',
                margin: 0,
                backgroundColor: '#dbeafe',
                color: '#000000',
                fontWeight: 500,
                whiteSpace: 'normal',
                height: 'auto',
              }}
            >
              <ul className="list-disc pl-4 space-y-2">
                <li>Event Activation Fee: $250</li>
                <li>Software Access Fee (1,000 badges): $1,000</li>
                <li>Print Agent License Fee (4 Licenses): $200</li>
                <li>
                  <Text strong>Total: $1,450</Text>
                </li>
              </ul>
            </Card>
            <Card
              title={
                <div className="w-full min-h-[80px] flex items-center">
                  <span className="block leading-tight max-w-[200px]">
                    Large Event
                    <br />
                    (5,000 Attendees, 8 Print Agent Licenses)
                  </span>
                </div>
              }
              className="shadow-md bg-white overflow-hidden"
              headStyle={{
                padding: '12px 24px',
                border: 'none',
                margin: 0,
                backgroundColor: '#dbeafe',
                color: '#000000',
                fontWeight: 500,
                whiteSpace: 'normal',
                height: 'auto',
              }}
            >
              <ul className="list-disc pl-4 space-y-2">
                <li>Event Activation Fee: $250</li>
                <li>Software Access Fee (5,000 badges): $3,750</li>
                <li>Print Agent License Fee (8 Licenses): $400</li>
                <li>
                  <Text strong>Total: $4,400</Text>
                </li>
              </ul>
            </Card>
          </div>
        </div>
      </div>
    </PartnerLayout>
  )
}
