import { ReactElement, useEffect, useState } from 'react'
import { Input, Button, Typography, Select, List, Divider, Form, InputNumber, message } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { PartnerDiscount, PartnerQuote, PartnerLineItem } from '../../models/manager/partner'
import { fetchPartnerDiscounts } from '../../services/partners'
import { useAuth0 } from '@auth0/auth0-react'
import { useProfileContext } from '../../contexts/profileContext'
import { errorHandler, generateReferenceId } from '../../utils/helper'

const { Title } = Typography
const { TextArea } = Input

interface QuoteEditorProps {
  initialQuote?: PartnerQuote
  onSubmit: (quote: Partial<PartnerQuote>) => Promise<void>
  submitButtonText: string
}

interface QuoteBreakdown {
  activationFee: number
  badgeCost: number
  badgeTotal: number
  printAgentLicenseTotal: number
  subtotal: number
  discountPercentage: number
  discountAmount: number
  lineItemsTotal: number
  credsnowFee: number
  total: number
}

export function QuoteEditor({ initialQuote, onSubmit, submitButtonText }: QuoteEditorProps): ReactElement {
  const { getAccessTokenSilently } = useAuth0()
  const { profile } = useProfileContext()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [discountLevels, setDiscountLevels] = useState<PartnerDiscount[]>([])
  const [lineItems, setLineItems] = useState<PartnerLineItem[]>(initialQuote?.PartnerLineItems || [])
  const [quoteBreakdown, setQuoteBreakdown] = useState<QuoteBreakdown | null>(null)

  useEffect(() => {
    loadDiscountLevels()
    if (initialQuote) {
      form.setFieldsValue({
        Name: initialQuote.Name,
        Description: initialQuote.Description,
        BadgeCount: initialQuote.BadgeCount,
        PrintAgentCount: initialQuote.PrintAgentCount,
        PartnerDiscountId: initialQuote.PartnerDiscountId,
      })
    }
  }, [])

  const loadDiscountLevels = async () => {
    try {
      const token = await getAccessTokenSilently()
      const discounts = await fetchPartnerDiscounts(token)
      setDiscountLevels(discounts)
    } catch (error) {
      message.error('Failed to load discount levels')
      errorHandler(error)
    }
  }

  const addLineItem = () => {
    setLineItems([...lineItems, { Id: 0, PartnerQuoteId: 0, Name: '', Amount: 0 }])
  }

  const removeLineItem = (index: number) => {
    setLineItems(lineItems.filter((_, i) => i !== index))
  }

  const updateLineItem = (index: number, field: keyof PartnerLineItem, value: string | number) => {
    const updatedItems = [...lineItems]
    updatedItems[index] = { ...updatedItems[index], [field]: value }
    setLineItems(updatedItems)
  }

  const calculateQuote = () => {
    const values = form.getFieldsValue()
    const badgeCount = values.BadgeCount || 0
    const printerCount = values.PrintAgentCount || 0
    const selectedDiscount = discountLevels.find((d) => d.Id === values.PartnerDiscountId)

    let badgeCost = 0
    if (badgeCount <= 1000) badgeCost = 1
    else if (badgeCount <= 2500) badgeCost = 0.75
    else if (badgeCount <= 10000) badgeCost = 0.5
    else if (badgeCount <= 25000) badgeCost = 0.4
    else badgeCost = 0.25

    const activationFee = 250
    const badgeTotal = badgeCount * badgeCost
    const printAgentLicenseTotal = printerCount * 50
    const subtotal = activationFee + badgeTotal + printAgentLicenseTotal

    const discountPercentage = selectedDiscount ? selectedDiscount.DiscountPercentage / 100 : 0
    const discountAmount = subtotal * discountPercentage

    const lineItemsTotal = lineItems.reduce((sum, item) => sum + (item.Amount || 0), 0)
    const credsnowFee = lineItemsTotal > 0 ? lineItemsTotal * 0.25 : 0
    const total = subtotal - discountAmount + lineItemsTotal + credsnowFee

    setQuoteBreakdown({
      activationFee,
      badgeCost,
      badgeTotal,
      printAgentLicenseTotal,
      subtotal,
      discountPercentage,
      discountAmount,
      lineItemsTotal,
      credsnowFee,
      total,
    })
  }

  useEffect(() => {
    calculateQuote()
  }, [lineItems, form.getFieldsValue()])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (values: any) => {
    try {
      setLoading(true)
      const quoteData: Partial<PartnerQuote> = {
        ...values,
        AccountId: profile.AccountId,
        PartnerLineItems: lineItems,
        Id: initialQuote?.Id || 0,
        ReferenceId: initialQuote?.ReferenceId || generateReferenceId(),
        UpdatedBy: profile.Email,
      }
      await onSubmit(quoteData)
    } catch (error) {
      message.error('Failed to save quote')
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical">
      <div className="space-y-6">
        <div>
          <Form.Item name="Name" label="Quote Name" rules={[{ required: true, message: 'Please enter a quote name' }]}>
            <Input placeholder="Enter quote name" />
          </Form.Item>

          <Form.Item name="Description" label="Description">
            <TextArea rows={4} placeholder="Enter quote description" />
          </Form.Item>
        </div>

        <Divider />

        <div>
          <Title level={4}>Badge and Print Agent Details</Title>
          <div className="grid grid-cols-2 gap-4">
            <Form.Item
              name="BadgeCount"
              label="Number of Badges"
              rules={[{ required: true, message: 'Please enter number of badges' }]}
            >
              <InputNumber min={1} className="w-full" />
            </Form.Item>

            <Form.Item
              name="PrintAgentCount"
              label="Number of Print Agents"
              rules={[{ required: true, message: 'Please enter number of print agents' }]}
            >
              <InputNumber min={0} className="w-full" />
            </Form.Item>
          </div>
        </div>

        <Divider />

        <div>
          <Title level={4}>Discount Level</Title>
          <Form.Item
            name="PartnerDiscountId"
            label="Select Discount Level"
            rules={[{ required: true, message: 'Please select a discount level' }]}
          >
            <Select>
              {discountLevels.map((level) => (
                <Select.Option key={level.Id} value={level.Id}>
                  {level.DiscountLevelName} ({level.DiscountPercentage}% discount)
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <Divider />

        <div>
          <div className="flex justify-between items-center mb-4">
            <Title level={4}>Additional Line Items</Title>
            <Button type="primary" onClick={addLineItem}>
              Add Line Item
            </Button>
          </div>

          <List
            dataSource={lineItems}
            renderItem={(item, index) => (
              <List.Item>
                <div className="grid grid-cols-12 gap-4 w-full">
                  <div className="col-span-6">
                    <Input
                      placeholder="Line item name"
                      value={item.Name}
                      onChange={(e) => updateLineItem(index, 'Name', e.target.value)}
                    />
                  </div>
                  <div className="col-span-4">
                    <InputNumber
                      className="w-full"
                      placeholder="Amount"
                      value={item.Amount}
                      onChange={(value) => updateLineItem(index, 'Amount', value || 0)}
                    />
                  </div>
                  <div className="col-span-2">
                    <Button danger icon={<DeleteOutlined />} onClick={() => removeLineItem(index)} />
                  </div>
                </div>
              </List.Item>
            )}
          />
        </div>

        {quoteBreakdown && (
          <>
            <Divider />
            <Title level={4}>Quote Breakdown</Title>
            <List className="bg-gray-50 p-4 rounded">
              <List.Item>
                <span>Event Activation Fee:</span>
                <span>${quoteBreakdown.activationFee.toFixed(2)}</span>
              </List.Item>
              <List.Item>
                <span>
                  Badge Cost ({form.getFieldValue('BadgeCount') || 0} @ ${quoteBreakdown.badgeCost.toFixed(2)} each):
                </span>
                <span>${quoteBreakdown.badgeTotal.toFixed(2)}</span>
              </List.Item>
              <List.Item>
                <span>Print Agent License Fee ({form.getFieldValue('PrintAgentCount') || 0} @ $50.00 each):</span>
                <span>${quoteBreakdown.printAgentLicenseTotal.toFixed(2)}</span>
              </List.Item>
              <List.Item>
                <span>Subtotal:</span>
                <span>${quoteBreakdown.subtotal.toFixed(2)}</span>
              </List.Item>
              {quoteBreakdown.discountAmount > 0 && (
                <List.Item>
                  <span>
                    Enterprise Discount (
                    {discountLevels.find((d) => d.Id === form.getFieldValue('PartnerDiscountId'))?.DiscountLevelName},{' '}
                    {(quoteBreakdown.discountPercentage * 100).toFixed(0)}%):
                  </span>
                  <span>-${quoteBreakdown.discountAmount.toFixed(2)}</span>
                </List.Item>
              )}
              {lineItems.map((item, index) => (
                <List.Item key={index}>
                  <span>{item.Name || 'Unnamed Item'}:</span>
                  <span>${(item.Amount || 0).toFixed(2)}</span>
                </List.Item>
              ))}
              {quoteBreakdown.lineItemsTotal > 0 && (
                <>
                  <List.Item>
                    <span>Line Items Total:</span>
                    <span>${quoteBreakdown.lineItemsTotal.toFixed(2)}</span>
                  </List.Item>
                  <List.Item>
                    <span>CredsNow Billing Fee (25%):</span>
                    <span>${quoteBreakdown.credsnowFee.toFixed(2)}</span>
                  </List.Item>
                </>
              )}
              <List.Item>
                <span className="font-bold">Total:</span>
                <span className="font-bold">${quoteBreakdown.total.toFixed(2)}</span>
              </List.Item>
            </List>
          </>
        )}

        <div className="flex justify-end">
          <Button type="primary" htmlType="submit" loading={loading}>
            {submitButtonText}
          </Button>
        </div>
      </div>
    </Form>
  )
}
