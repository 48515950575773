import { ReactElement, useState } from 'react'
import { PartnerLayout } from '../../components/layout/PartnerLayout'
import { Table, Card, Button, Row, Col, Statistic, message } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'

// Import mock customers for company name lookup
const mockCustomers = [
  {
    companyName: 'Acme Corporation',
    primaryContact: 'John Smith',
    eventCount: 5,
    accountId: 1,
  },
  {
    companyName: 'TechStart Inc',
    primaryContact: 'Sarah Johnson',
    eventCount: 3,
    accountId: 2,
  },
  {
    companyName: 'Global Events Ltd',
    primaryContact: 'Michael Brown',
    eventCount: 8,
    accountId: 3,
  },
]

interface EventData {
  eventName: string
  eventDate: string
  status: string
  attendeeCount: number
  eventId: number
}

// Placeholder data for UI design
const mockEvents: EventData[] = [
  {
    eventName: 'Annual Conference 2025',
    eventDate: '2025-06-15',
    status: 'Upcoming',
    attendeeCount: 500,
    eventId: 1,
  },
  {
    eventName: 'Tech Summit Q2',
    eventDate: '2025-04-20',
    status: 'Planning',
    attendeeCount: 300,
    eventId: 2,
  },
  {
    eventName: 'Partner Showcase',
    eventDate: '2025-03-10',
    status: 'Completed',
    attendeeCount: 250,
    eventId: 3,
  },
  {
    eventName: 'Annual Conference 2026',
    eventDate: '2026-06-15',
    status: 'Upcoming',
    attendeeCount: 500,
    eventId: 4,
  },
  {
    eventName: 'Tech Summit Q3',
    eventDate: '2026-04-20',
    status: 'Planning',
    attendeeCount: 300,
    eventId: 5,
  },
]

export default function CustomerEvents(): ReactElement {
  const navigate = useNavigate()
  const { accountId } = useParams<{ accountId: string }>()
  const [loading] = useState(false)

  // Filter events based on accountId (mock data for now)
  const [events] = useState<EventData[]>(mockEvents.filter((_, index) => index < (Number(accountId) || 1) * 2))

  // Get company name based on accountId (mock data for now)
  const companyName = mockCustomers.find((c) => c.accountId === Number(accountId))?.companyName || 'Unknown Company'

  const columns: ColumnsType<EventData> = [
    {
      title: 'Event Name',
      dataIndex: 'eventName',
      key: 'eventName',
      sorter: (a, b) => a.eventName.localeCompare(b.eventName),
    },
    {
      title: 'Event Date',
      dataIndex: 'eventDate',
      key: 'eventDate',
      sorter: (a, b) => a.eventDate.localeCompare(b.eventDate),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        const colorMap: { [key: string]: string } = {
          Upcoming: 'text-blue-600',
          Planning: 'text-orange-600',
          Completed: 'text-green-600',
        }
        return <span className={colorMap[status] || ''}>{status}</span>
      },
    },
    {
      title: 'Attendee Count',
      dataIndex: 'attendeeCount',
      key: 'attendeeCount',
      sorter: (a, b) => a.attendeeCount - b.attendeeCount,
    },
  ]

  return (
    <PartnerLayout>
      <div className="p-8 pt-4 bg-gray-50 min-h-screen">
        <Button
          type="link"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate('/partners/customers')}
          className="mb-4 pl-0 text-[#2563eb] hover:text-[#1d4ed8]"
        >
          Back to Customers
        </Button>

        <div className="mb-6">
          <Card
            className="shadow-md bg-white overflow-hidden mb-6"
            title={
              <div className="bg-[#EBF5FF] -mx-6 px-6 py-3">
                <span className="text-[#2563eb] font-medium pl-4 text-lg tracking-wide">{companyName} Events</span>
              </div>
            }
            headStyle={{
              padding: 0,
              border: 'none',
              marginBottom: 0,
              backgroundColor: '#EBF5FF',
            }}
            bodyStyle={{
              padding: '24px',
            }}
          >
            <Row gutter={16} className="mb-6">
              <Col span={8}>
                <Statistic title="Total Events" value={events.length} />
              </Col>
              <Col span={8}>
                <Statistic
                  title="Total Attendees"
                  value={events.reduce((sum, event) => sum + event.attendeeCount, 0)}
                />
              </Col>
              <Col span={8}>
                <div className="flex items-center justify-between">
                  <Statistic
                    title="Upcoming Events"
                    value={events.filter((event) => event.status === 'Upcoming').length}
                  />
                  <Button
                    type="primary"
                    className="bg-[#2563eb] hover:bg-[#1d4ed8] ml-4 mt-4"
                    onClick={() => {
                      // Invoice functionality will be added later
                      message.info('Invoice feature coming soon')
                    }}
                  >
                    Invoice
                  </Button>
                </div>
              </Col>
            </Row>

            <Table
              columns={columns}
              dataSource={events}
              rowKey="eventId"
              loading={loading}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} events`,
              }}
            />
          </Card>
        </div>
      </div>
    </PartnerLayout>
  )
}
