import { Fragment, ReactElement, ReactNode, useState, useEffect } from 'react'
import { AppHeader } from './AppHeader'
import { Layout, Menu, MenuProps } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { useAuth0 } from '@auth0/auth0-react'
import { AuthLayout } from './Auth/AuthLayout'
import { SignupInfo } from '../shared/SignupInfo'
import { useProfileContext } from '../../contexts/profileContext'
import { Link, useLocation } from 'react-router-dom'
import LeftArrow from '../../assets/images/events/leftArrow.svg'
import RightArrow from '../../assets/images/events/rightArrow.svg'
import { fetchAccount } from '../../services/accounts'
import DashboardIcon from '../../assets/images/events/dashboard.svg'
import CalculatorIcon from '../../assets/images/partner/calculator.svg'
import { FileTextOutlined, UserOutlined } from '@ant-design/icons'

interface Props {
  children: ReactNode
}

type MenuItem = Required<MenuProps>['items'][number] & { path?: string }

function getItem(
  label: ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  path?: string
): MenuItem {
  return {
    key,
    icon: <div className="w-[22px] h-full flex items-center justify-center">{icon}</div>,
    children,
    label: path ? <Link to={path}>{label}</Link> : label,
    style: !path ? { cursor: 'default' } : undefined,
  } as MenuItem
}

const items: MenuItem[] = [
  getItem(
    <span className="font-semibold">Dashboard</span>,
    '/partners/dashboard',
    <img src={DashboardIcon} alt="Dashboard" className="w-[20px] h-[20px]" />,
    undefined,
    '/partners/dashboard'
  ),
  getItem(
    <span className="font-semibold">Quotes</span>,
    '/partners/quotes',
    <img src={CalculatorIcon} alt="Quotes" className="w-[20px] h-[20px]" />,
    undefined,
    '/partners/quotes'
  ),
  getItem(
    <span className="font-semibold">Customers</span>,
    '/partners/customers',
    <UserOutlined className="text-gray-600 text-xl" />,
    undefined,
    '/partners/customers'
  ),
  getItem(
    <span className="font-semibold">Invoices</span>,
    '/partners/invoices',
    <FileTextOutlined className="text-gray-600 text-xl" />,
    undefined,
    '/partners/invoices'
  ),
]

export function PartnerLayout({ children }: Props): ReactElement {
  const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0()
  const { profile, saveProfile } = useProfileContext()
  const { Header, Sider } = Layout
  const { pathname } = useLocation()
  const [collapsed, setCollapsed] = useState(false)
  const [showProfileModal, setShowProfileModal] = useState(false)

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect()
    }

    if (localStorage.getItem('p') != null) {
      if (!profile.IsProfileComplete) {
        saveProfile({ ...profile, IsProfileComplete: false })
        setShowProfileModal(true)
      } else {
        saveProfile({ ...profile, IsProfileComplete: true })
      }
    }

    if (profile.IsProfileComplete) {
      if (!profile.IsPlanComplete) {
        getAccessTokenSilently().then(async (token) => {
          try {
            const userAccount = await fetchAccount(profile.AccountId, token)
            if (userAccount) {
              saveProfile({ ...profile, IsPlanComplete: true })
            }
          } catch (error) {
            // Handle error silently
          }
        })
      }
    }
  }, [isLoading, isAuthenticated, profile])

  const modalStyle = `mainLayout !bg-PrimaryBgWhite-100 min-h-screen`

  return (
    <Fragment>
      {!profile.IsProfileComplete ? (
        <AuthLayout>
          <SignupInfo isOpen={showProfileModal} />
        </AuthLayout>
      ) : (
        <Layout className={modalStyle} style={{ maxHeight: '100vh' }}>
          <Header className="sticky top-0 z-100 shadow-md">
            <AppHeader />
            <div className="h-[10px]" />
          </Header>
          <Layout className="">
            <Sider
              trigger={
                <div className="flex justify-end mr-2">
                  {collapsed && <img src={RightArrow} className="transition-all ease-in" />}
                  {!collapsed && <img src={LeftArrow} className="transition-all ease-in" />}
                </div>
              }
              theme="light"
              collapsible
              collapsed={collapsed}
              onCollapse={(value) => setCollapsed(value)}
              collapsedWidth={75}
              width={200}
              style={{ height: 'calc(100vh - 80px)', overflow: 'hidden', position: 'sticky', top: 0, left: 0 }}
            >
              <Menu
                activeKey="pathname"
                className="mt-4"
                selectedKeys={[pathname]}
                defaultSelectedKeys={[pathname]}
                theme="light"
                mode="inline"
                items={items}
              />
            </Sider>
            <Layout className="!bg-PrimaryBgWhite-100 px-4">
              <Content
                className="rounded-lg bg-SecondaryBgWhite-100 p-4 overflow-y-auto"
                style={{ height: 'calc(100vh - 64px)' }}
              >
                {children}
              </Content>
            </Layout>
          </Layout>
        </Layout>
      )}
    </Fragment>
  )
}
