import { ReactElement, useEffect, useState } from 'react'
import { Card, message } from 'antd'
import { PartnerLayout } from '../../components/layout/PartnerLayout'
import { QuoteEditor } from '../../components/partners/QuoteEditor'
import { useAuth0 } from '@auth0/auth0-react'
import { fetchPartnerQuote, updatePartnerQuote } from '../../services/partners'
import { useNavigate, useParams } from 'react-router-dom'
import { PartnerQuote } from '../../models/manager/partner'
import { LoadingSpinner } from '../../components/shared/LoadingSpinner'
import { errorHandler } from '../../utils/helper'

export default function EditQuote(): ReactElement {
  const { getAccessTokenSilently } = useAuth0()
  const navigate = useNavigate()
  const { referenceId } = useParams()
  const [quote, setQuote] = useState<PartnerQuote>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    loadQuote()
  }, [])

  const loadQuote = async () => {
    try {
      if (!referenceId) {
        message.error('Quote reference ID is required')
        navigate('/partners/quotes')
        return
      }

      const token = await getAccessTokenSilently()
      const quoteData = await fetchPartnerQuote(referenceId, token)
      setQuote(quoteData)
    } catch (error) {
      message.error('Failed to load quote')
      errorHandler(error)
      navigate('/partners/quotes')
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = async (quoteData: Partial<PartnerQuote>) => {
    const token = await getAccessTokenSilently()
    await updatePartnerQuote(quoteData as PartnerQuote, token)
    navigate('/partners/quotes')
  }

  if (loading) {
    return <LoadingSpinner isLoading={true} label="Loading quote..." />
  }

  return (
    <PartnerLayout>
      <Card className="m-8">
        <div className="bg-[#EBF5FF] -mx-6 px-6 py-3 mb-6">
          <h1 className="text-2xl font-bold text-[#2563eb]">
            Edit Quote
            {quote && quote.ReferenceId && (
              <span className="text-sm font-normal text-gray-500 ml-2">(Reference ID: {quote.ReferenceId})</span>
            )}
          </h1>
        </div>
        {quote && <QuoteEditor initialQuote={quote} onSubmit={handleSubmit} submitButtonText="Save Changes" />}
      </Card>
    </PartnerLayout>
  )
}
