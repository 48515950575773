import { ReactElement, useState } from 'react'
import { Table, Card, Input, Button } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useNavigate } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons'
import { PartnerLayout } from '../../components/layout/PartnerLayout'

interface CustomerData {
  companyName: string
  primaryContact: string
  eventCount: number
  accountId: number
}

// Placeholder data for UI design
const mockCustomers: CustomerData[] = [
  {
    companyName: 'Acme Corporation',
    primaryContact: 'John Smith',
    eventCount: 5,
    accountId: 1,
  },
  {
    companyName: 'TechStart Inc',
    primaryContact: 'Sarah Johnson',
    eventCount: 3,
    accountId: 2,
  },
  {
    companyName: 'Global Events Ltd',
    primaryContact: 'Michael Brown',
    eventCount: 8,
    accountId: 3,
  },
]

export default function PartnerCustomers(): ReactElement {
  const navigate = useNavigate()
  const [loading] = useState(false)
  const [customers] = useState<CustomerData[]>(mockCustomers)
  const [searchText, setSearchText] = useState('')

  const columns: ColumnsType<CustomerData> = [
    {
      title: 'Company Account Name',
      dataIndex: 'companyName',
      key: 'companyName',
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
      filteredValue: [searchText],
      onFilter: (value, record) =>
        record.companyName.toLowerCase().includes(value.toString().toLowerCase()) ||
        record.primaryContact.toLowerCase().includes(value.toString().toLowerCase()),
    },
    {
      title: 'Primary Contact',
      dataIndex: 'primaryContact',
      key: 'primaryContact',
    },
    {
      title: 'Number of Events',
      dataIndex: 'eventCount',
      key: 'eventCount',
      sorter: (a, b) => a.eventCount - b.eventCount,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/partners/customers/${record.accountId}/events`)}
          className="text-[#2563eb] hover:text-[#1d4ed8]"
        >
          View Events
        </Button>
      ),
    },
  ]

  return (
    <PartnerLayout>
      <div className="p-8 pt-4 bg-gray-50 min-h-screen">
        <div className="mb-6">
          <Card
            className="shadow-md bg-white overflow-hidden"
            title={
              <div className="bg-[#EBF5FF] -mx-6 px-6 py-3">
                <span className="text-[#2563eb] font-medium pl-4 text-lg tracking-wide">Customer Accounts</span>
              </div>
            }
            headStyle={{
              padding: 0,
              border: 'none',
              marginBottom: 0,
              backgroundColor: '#EBF5FF',
            }}
            bodyStyle={{
              padding: '24px',
            }}
          >
            <div className="mb-4">
              <Input
                placeholder="Search by company name or contact"
                prefix={<SearchOutlined />}
                onChange={(e) => setSearchText(e.target.value)}
                className="max-w-md"
              />
            </div>
            <Table
              columns={columns}
              dataSource={customers}
              rowKey="accountId"
              loading={loading}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} customers`,
              }}
            />
          </Card>
        </div>
      </div>
    </PartnerLayout>
  )
}
