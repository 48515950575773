import { PartnerDiscount, PartnerQuote } from '../models/manager/partner'
import { apiRequest } from '../utils/axios'

export async function fetchPartnerQuotes(accountId: number, token: string): Promise<PartnerQuote[]> {
  return apiRequest('GET', `/partnerQuotes/${accountId}`, token)
}

export async function fetchPartnerDiscounts(token: string): Promise<PartnerDiscount[]> {
  return apiRequest('GET', `/partnerDiscounts`, token)
}

export async function fetchPartnerQuote(referenceId: string, token: string): Promise<PartnerQuote> {
  return apiRequest('GET', `/partnerQuote/${referenceId}`, token)
}

export async function addPartnerQuote(partnerQuote: PartnerQuote, token: string): Promise<PartnerQuote> {
  return apiRequest('POST', `/partnerQuote`, token, partnerQuote)
}

export async function updatePartnerQuote(partnerQuote: PartnerQuote, token: string): Promise<PartnerQuote> {
  return apiRequest('PUT', `/partnerQuote`, token, partnerQuote)
}

export async function deletePartnerQuote(id: number, token: string): Promise<void> {
  return apiRequest('DELETE', `/partnerQuote/${id}`, token)
}
