import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios'
import { apiFormDataRequest, apiRequest } from '../utils/axios'
import { BlobRenameDto } from '../models/Dtos/BlobRenameDto'
import { kioskApiRequest } from './kioskApp'
import { isKioskMode } from '../utils/helper'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export async function fetchFile(fileName: string, token: string, kioskKey?: string): Promise<string> {
  let imageUrl = ''

  if (isKioskMode(kioskKey)) {
    await axios
      .get(`${API_BASE_URL}/kioskApp/blob/${fileName}`, {
        responseType: 'blob',
        headers: { 'X-Kiosk-Key': kioskKey },
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] })
        imageUrl = URL.createObjectURL(blob)
      })
  } else {
    await axios
      .get(`${API_BASE_URL}/blob/${fileName}`, {
        responseType: 'blob',
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] })
        imageUrl = URL.createObjectURL(blob)
      })
  }

  return imageUrl
}

export async function fetchFileAsDataUrl(
  fileName: string,
  token: string,
  kioskKey?: string
): Promise<{ data: string; name: string }> {
  if (kioskKey && kioskKey.length > 0) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<{ data: string; name: string }>(async (resolve, reject) => {
      try {
        const url = `${API_BASE_URL}/kioskApp/blob/${fileName}`
        const response = await axios.get(url, {
          responseType: 'blob',
          headers: {
            'X-Kiosk-Key': kioskKey,
          },
        })

        const blob = new Blob([response.data], { type: response.headers['content-type'] })
        const reader = new FileReader()

        reader.onload = function (event) {
          const base64data = (event.target?.result ?? '') as string
          resolve({ data: base64data, name: fileName })
        }

        reader.readAsDataURL(blob)
      } catch (error) {
        reject(error)
      }
    })
  } else {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<{ data: string; name: string }>(async (resolve, reject) => {
      axios
        .get(`${API_BASE_URL}/blob/${fileName}`, {
          responseType: 'blob',
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const reader = new FileReader()

          reader.onload = function (event) {
            const base64data = (event.target?.result ?? '') as string
            resolve({ data: base64data, name: fileName })
          }

          reader.readAsDataURL(blob)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export async function saveFile(fileData: FormData, token: string): Promise<void> {
  return apiFormDataRequest('POST', '/blob', token, fileData)
}

export async function saveCollateral(repo: string, fileData: FormData, token: string): Promise<void> {
  return apiFormDataRequest('PUT', `/blob/${repo}`, token, fileData)
}

export async function deleteFile(repo: string, filename: string, token: string): Promise<void> {
  return apiRequest('DELETE', `/blob/${repo}/${filename}`, token)
}

export async function fetchFolderItems(repo: string, folder: string, token: string): Promise<string[]> {
  return apiRequest('GET', `/blobFolder/${repo}/${folder}`, token)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export async function saveImageWithProgress(options: any, token: string, kioskKey?: string): Promise<void> {
  const { onSuccess, onError, file, onProgress, data } = options

  const fileData = new FormData()
  const config: AxiosRequestConfig = {
    headers: {
      'content-type': 'multipart/form-data',
      ...(isKioskMode(kioskKey) ? { 'X-Kiosk-Key': kioskKey } : { Authorization: `Bearer ${token}` }),
    },
    onUploadProgress: (event: AxiosProgressEvent) => {
      onProgress({ percent: (event.loaded / (event.total ?? 0)) * 100 })
    },
  }
  fileData.append('file', file)
  fileData.append('folder', data['folder'])
  fileData.append('storageName', data['storageName'])
  try {
    if (isKioskMode(kioskKey)) {
      await axios.post(`${API_BASE_URL}/kioskApp/blob`, fileData, config)
    } else {
      await axios.post(`${API_BASE_URL}/blob`, fileData, config)
    }

    onSuccess(file)
  } catch (err) {
    onError({ err })
  }
}

export async function renameFile(renameInfo: BlobRenameDto, token: string, kioskKey?: string): Promise<void> {
  return kioskKey && kioskKey.length > 0
    ? kioskApiRequest('PATCH', '/kioskApp/blob', { 'X-Kiosk-Key': kioskKey }, renameInfo)
    : apiRequest('PATCH', '/blob', token, renameInfo)
}
