import { ReactElement, Suspense } from 'react'
import { LoadingSpinner } from '../components/shared/LoadingSpinner'
import { Navigate, Route, Routes } from 'react-router-dom'
import PartnerDashboard from '../pages/partners/PartnerDashboard'
import PartnerQuotes from '../pages/partners/PartnerQuotes'
import CreateQuote from '../pages/partners/CreateQuote'
import EditQuote from '../pages/partners/EditQuote'
import PartnerCustomers from '../pages/partners/PartnerCustomers'
import CustomerEvents from '../pages/partners/CustomerEvents'
import PartnerInvoices from '../pages/partners/PartnerInvoices'
import EditInvoice from '../pages/partners/EditInvoice'
import ViewInvoice from '../pages/partners/ViewInvoice'

export const PartnerRoutes = (): ReactElement => {
  return (
    <Suspense fallback={<LoadingSpinner isLoading={true} label="Loading..." />}>
      <Routes>
        <Route path="/*" element={<Navigate replace to="/partners/dashboard" />} />
        <Route path="/dashboard" element={<PartnerDashboard />} />
        <Route path="/quotes" element={<PartnerQuotes />} />
        <Route path="/quotes/create" element={<CreateQuote />} />
        <Route path="/quotes/:referenceId" element={<EditQuote />} />
        <Route path="/customers" element={<PartnerCustomers />} />
        <Route path="/customers/:accountId/events" element={<CustomerEvents />} />
        <Route path="/invoices" element={<PartnerInvoices />} />
        <Route path="/invoices/create" element={<EditInvoice />} />
        <Route path="/invoices/:id" element={<EditInvoice />} />
        <Route path="/invoices/:id/view" element={<ViewInvoice />} />
      </Routes>
    </Suspense>
  )
}
