import { ReactElement } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Table, Typography, Button, Tag, Descriptions } from 'antd'
import { mockInvoices } from '../../models/manager/invoice'
import { EditOutlined } from '@ant-design/icons'
import { PartnerLayout } from '../../components/layout/PartnerLayout'

const { Title } = Typography

export default function ViewInvoice(): ReactElement {
  const { id } = useParams()
  const navigate = useNavigate()

  const invoice = mockInvoices.find((inv) => inv.id === Number(id))

  if (!invoice) {
    return <div>Invoice not found</div>
  }

  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right' as const,
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      align: 'right' as const,
      render: (price: number) => `$${price.toFixed(2)}`,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      align: 'right' as const,
      render: (total: number) => `$${total.toFixed(2)}`,
    },
  ]

  const getStatusTag = (status: string) => {
    const statusColors = {
      Draft: 'default',
      Issued: 'processing',
      Paid: 'success',
    }
    return <Tag color={statusColors[status as keyof typeof statusColors]}>{status}</Tag>
  }

  return (
    <PartnerLayout>
      <div className="max-w-4xl mx-auto">
        <Card className="shadow-md">
          <div className="flex justify-between items-start mb-6">
            <div>
              <Title level={4}>Invoice {invoice.invoiceNumber}</Title>
              <div className="text-gray-500">Quote Reference: {invoice.quoteReference}</div>
            </div>
            <div className="space-x-4">
              <Button icon={<EditOutlined />} onClick={() => navigate(`/partners/invoices/${id}`)}>
                Edit
              </Button>
            </div>
          </div>

          <Descriptions bordered column={2} className="mb-8">
            <Descriptions.Item label="Status">{getStatusTag(invoice.status)}</Descriptions.Item>
            <Descriptions.Item label="Customer Name">{invoice.customerName}</Descriptions.Item>
            <Descriptions.Item label="Issue Date">{invoice.issueDate}</Descriptions.Item>
            <Descriptions.Item label="Due Date">{invoice.dueDate}</Descriptions.Item>
          </Descriptions>

          <div className="mb-8">
            <Title level={5}>Items</Title>
            <Table
              columns={columns}
              dataSource={invoice.items}
              pagination={false}
              rowKey="id"
              summary={() => {
                const subtotal = invoice.subtotal
                const tax = invoice.tax
                const total = invoice.total

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={3} className="text-right">
                        Subtotal
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} className="text-right">
                        ${subtotal.toFixed(2)}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={3} className="text-right">
                        Tax (10%)
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} className="text-right">
                        ${tax.toFixed(2)}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={3} className="text-right font-bold">
                        Total
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} className="text-right font-bold">
                        ${total.toFixed(2)}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                )
              }}
            />
          </div>

          <div className="flex justify-end">
            <Button onClick={() => navigate('/partners/invoices')}>Back to Invoices</Button>
          </div>
        </Card>
      </div>
    </PartnerLayout>
  )
}
