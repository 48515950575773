import { ReactElement } from 'react'
import { Table, Space, Button, Tooltip, Tag, message } from 'antd'
import { EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { Invoice } from '../../models/manager/invoice'
import { SiteButton } from '../controls/SiteButton'
import type { ColumnsType } from 'antd/es/table'

interface Props {
  invoices: Invoice[]
  loading: boolean
}

export function InvoiceList({ invoices, loading }: Props): ReactElement {
  const navigate = useNavigate()

  const getStatusTag = (status: string) => {
    const statusColors = {
      Draft: 'default',
      Issued: 'processing',
      Paid: 'success',
    }
    return <Tag color={statusColors[status as keyof typeof statusColors]}>{status}</Tag>
  }

  const columns: ColumnsType<Invoice> = [
    {
      title: 'Invoice #',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      sorter: (a: Invoice, b: Invoice) => a.invoiceNumber.localeCompare(b.invoiceNumber),
    },
    {
      title: 'Quote Ref',
      dataIndex: 'quoteReference',
      key: 'quoteReference',
    },
    {
      title: 'Customer',
      dataIndex: 'customerName',
      key: 'customerName',
      sorter: (a: Invoice, b: Invoice) => a.customerName.localeCompare(b.customerName),
    },
    {
      title: 'Issue Date',
      dataIndex: 'issueDate',
      key: 'issueDate',
      sorter: (a: Invoice, b: Invoice) => a.issueDate.localeCompare(b.issueDate),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => getStatusTag(status),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (total: number) => `$${total.toFixed(2)}`,
      sorter: (a: Invoice, b: Invoice) => a.total - b.total,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: unknown, record: Invoice) => (
        <Space>
          <Tooltip title="Edit">
            <Button type="link" icon={<EditOutlined />} onClick={() => navigate(`/partners/invoices/${record.id}`)} />
          </Tooltip>
          <Tooltip title="View">
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => navigate(`/partners/invoices/${record.id}/view`)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              type="link"
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                message.info('Delete functionality coming soon')
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ]

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div></div>
        <SiteButton
          label="Create New Invoice"
          id="createInvoice"
          onClick={() => navigate('/partners/invoices/create')}
        />
      </div>
      <Table loading={loading} columns={columns} dataSource={invoices} rowKey="id" pagination={{ pageSize: 10 }} />
    </div>
  )
}
