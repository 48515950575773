import axios, { Method } from 'axios'
import { PrintAgent } from '../models/manager/printAgent'
import { KioskData, KioskPrintRequest, KioskSearchResultsData } from '../models/manager/kioskApp'
import { EventData } from '../models/manager/event'
import { BlobData } from '../models/manager/blob'
import { EVENT_IMAGE_FOLDER } from '../constants/common'
import { ApiResponse } from '../models/manager/apiResponse'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export async function fetchKioskConfigs(kioskKey: string): Promise<ApiResponse<KioskData[]>> {
  return kioskApiRequest('GET', `/kioskApp/configs`, { 'X-Kiosk-Key': kioskKey })
}

export async function fetchConfig(
  kioskKey: string,
  configId: number,
  lastUpdate?: Date
): Promise<ApiResponse<KioskData>> {
  let url = `/kioskApp/config/${configId}`
  if (lastUpdate) url += `?lastUpdate=${encodeURIComponent(lastUpdate.toString())}`
  return kioskApiRequest('GET', url, { 'X-Kiosk-Key': kioskKey })
}

export async function fetchKioskEventInfo(kioskKey: string, lastUpdate?: Date): Promise<ApiResponse<EventData>> {
  let url = `/kioskApp/eventInfo`
  if (lastUpdate) url += `?lastUpdate=${encodeURIComponent(lastUpdate.toString())}`
  return kioskApiRequest('GET', url, { 'X-Kiosk-Key': kioskKey })
}

export async function fetchKioskPrintAgents(kioskKey: string): Promise<ApiResponse<PrintAgent[]>> {
  return kioskApiRequest('GET', `/kioskApp/printAgents`, { 'X-Kiosk-Key': kioskKey })
}

export async function kioskPrintBadge(
  kioskKey: string,
  kioskPrintRequest: KioskPrintRequest
): Promise<ApiResponse<PrintAgent[]>> {
  return kioskApiRequest('POST', `/kioskApp/print`, { 'X-Kiosk-Key': kioskKey }, kioskPrintRequest)
}

export async function fetchKioskAvatar(kioskKey: string, eventId: number): Promise<BlobData> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<BlobData>(async (resolve, reject) => {
    try {
      const filename = `${EVENT_IMAGE_FOLDER}/${eventId}.*`
      const url = `${API_BASE_URL}/kioskApp/blob/${filename}`
      const response = await axios.get(url, {
        responseType: 'blob',
        headers: {
          'X-Kiosk-Key': kioskKey,
        },
      })

      const blob = new Blob([response.data], { type: response.headers['content-type'] })
      const reader = new FileReader()

      reader.onload = function (event) {
        const base64data = (event.target?.result ?? '') as string
        resolve({ data: base64data, name: filename })
      }

      reader.readAsDataURL(blob)
    } catch (error) {
      reject(error)
    }
  })
}

export async function fetchKioskSearchResults(
  kioskKey: string,
  params: Record<string, string | number | boolean>
): Promise<ApiResponse<KioskSearchResultsData[]>> {
  const query = new URLSearchParams(Object.entries(params).map(([key, value]) => [key, String(value)]))
  return kioskApiRequest('GET', `/kioskApp/search?${query}`, { 'X-Kiosk-Key': kioskKey })
}

export async function kioskApiRequest(
  method: Method,
  url: string,
  headers: Record<string, string> = {},
  data?: unknown
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const request = axios({
      url: API_BASE_URL + url,
      method,
      headers,
      data,
    })

    request
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
