import { ReactElement } from 'react'
import { AppHeaderLogo } from './AppHeaderLogo'
import { AppHeaderDesktopMenu } from './AppHeaderDesktopMenu'
import { AppHeaderMobileMenu } from './AppHeaderMobileMenu'
import { AppHeaderProfile } from './AppHeaderProfile'

export function AppHeader(): ReactElement {
  return (
    <div className="container-fluid site_header_wrapper">
      <div className="header px-6">
        <AppHeaderLogo />
        <div className="mobileHidden flex justify-end items-center mt-[10px] gap-6 flex-1">
          <div className="flex-1">
            <AppHeaderDesktopMenu />
          </div>
          <AppHeaderProfile context="desktop" />
        </div>
        <div className="mobileVisible">
          <AppHeaderMobileMenu />
        </div>
      </div>
    </div>
  )
}
