import { ReactElement } from 'react'
import { Card } from 'antd'
import { PartnerLayout } from '../../components/layout/PartnerLayout'
import { QuoteEditor } from '../../components/partners/QuoteEditor'
import { useAuth0 } from '@auth0/auth0-react'
import { addPartnerQuote } from '../../services/partners'
import { useNavigate } from 'react-router-dom'
import { PartnerQuote } from '../../models/manager/partner'

export default function CreateQuote(): ReactElement {
  const { getAccessTokenSilently } = useAuth0()
  const navigate = useNavigate()

  const handleSubmit = async (quoteData: Partial<PartnerQuote>) => {
    const token = await getAccessTokenSilently()
    await addPartnerQuote(quoteData as PartnerQuote, token)
    navigate('/partners/quotes')
  }

  return (
    <PartnerLayout>
      <Card>
        <div className="flex justify-center items-center bg-SecondaryBgWhite-100 rounded-lg px-6 mb-6">
          <h1 className="text-xl font-semibold text-PrimaryDarkBlue-100 flex-1">Create New Quote</h1>
        </div>
        <QuoteEditor onSubmit={handleSubmit} submitButtonText="Create Quote" />
      </Card>
    </PartnerLayout>
  )
}
